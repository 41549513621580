@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  font-family: Poppins, sans-serif;
}

p, h1, h2, h3, h4, h5, h6, a, div {
  color: #177140;
}

body {
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}
