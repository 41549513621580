.contactSection {
    max-width: 1200px;
    margin: 0;
    padding-top: 200px;


    display: flex;
    justify-content: center;
    align-items: center;

    .contactContainer {
        padding: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        background-color: #0E2018;
        border: 2px solid rgba(43, 103, 77, 0.04);
        border-radius: 15px;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.114);

        h1 {
            position: relative;
            color: white;
            font-weight: 500;
            font-size: 35px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                width: 50px;
                border-bottom: 2px solid white;
                transition: 0.5s ease;
            }
        }

        p {
            line-height: 30px;
            color: gray;
        }

        .contactForm {
            width: 100%;
            margin: 0;
            padding: 0;
            box-sizing: border-box;

            ul {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 1rem;

                margin: 0;
                padding: 0;
            }

            input {
                color: white;
                height: 40px;
                border-radius: 10px;
                text-indent: 10px;
                border: 2px solid #264038;
                box-sizing: border-box;

                transition: 0.3s ease;

                background-color: #264038;
                outline: none;

                &::placeholder {
                    color: rgb(186, 186, 186);
                }

                &:focus {
                    background-color: #457466;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.166);
                }
            }

            .nomCourriel {
                width: 100%;
                display: flex;
                gap: 1rem;

                li {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
            }

            .objet {
                width: 100%;

                input {
                    width: 100%;
                }
            }

            .textarea {
                width: 100%;

                textarea {
                    width: 100%;
                    height: 150px;

                    border: none;
                    border-radius: 10px;
                    padding: 10px;

                    outline: none;
                    background-color: #264038;
                    border: 2px solid #264038;
                    color: white;
                    transition: 0.3s ease;

                    box-sizing: border-box;

                    &::placeholder {
                        color: rgb(186, 186, 186);
                    }

                    &:focus {
                        background-color: #457466;
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.166);
                    }
                }
            }

            .contactButton {
                width: 150px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-indent: 0px;
                margin: 0;
                padding: 0;
                background-color: #177140;
                border-radius: 10px;

                cursor: pointer;

                transition: all 0.2s ease;

                border: 1px solid rgba(255, 255, 255, 0.174);

                &:hover {
                    background-color: #10532e;
                }

                &:active {
                    background-color: #0e1e15;
                }
            }

        }
    }
}