.card {
    position: relative;
    width: 300px;
    height: 500px;

    background-color: rgb(14, 32, 24);
    border-radius: 15px;
    border: 2px solid rgba(43, 103, 77, 0.04);
    padding: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: 40px 20px 50px rgba(0, 0, 0, 0.178);

    cursor: pointer;

    transition: 0.3s ease;

    @media screen and (max-width: 600px) {
        width: 250px;
        height: 450px;
    }

    &:hover {
        box-shadow: 40px 20px 50px rgba(0, 0, 0, 0.398);
        transform: translateY(-30px);
    }

    .face {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: 0.3s ease;
    }

    .back {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        transition: 0.3s ease;

        p {
            text-align: center;
            margin-bottom: 2rem;
            color: #457466;
            margin-top: 0;
        }
    }

    h1 {
        width: 90%;
        font-weight: 400;
        font-size: 40px;
        color: rgb(195, 195, 195);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 250px;

        @media screen and (max-width: 600px) {
            width: 200px;
        }
    }

    .visible {
        opacity: 1;
        z-index: 5;
    }

    .invisible {
        opacity: 0;
        z-index: -5;
    }
}