.accordion {
    position: relative;
    width: 100%;
    margin: auto;
    background-color: rgb(14, 32, 24);
    border-radius: 5px;
    border: 2px solid rgba(43, 103, 77, 0.04);

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: rgb(15, 35, 26);
    }

    .accordionHeading {
        width: 100%;

        .container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h2 {
                font-weight: 400;
                color: rgb(195, 195, 195);
                margin-left: 1.5rem;
            }

            .icon {
                cursor: pointer;
                transition: 0.3s ease-in-out;
                margin-right: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .open {
                transform: rotate(45deg);
            }
        }
    }

    .accordionContent {
        overflow: hidden;
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out 0.3s;
        max-height: 0px;
        opacity: 0;
        width: 100%;

        p {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }

    .open {
        max-height: 1000px;
        opacity: 1;
        transition-delay: 0s;
    }
}