.structure {
    max-width: 1200px;
    padding-top: 100px;

    h1 {
        position: relative;
        font-size: 70px;
        font-weight: 500;
        color: white;

        @media screen and (max-width: 600px) {
            text-align: center;
            font-size: 50px;
        }
    }

    p {
        width: 100%;
        color: rgb(156, 156, 156);
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
    }
}