.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
}

.appContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}