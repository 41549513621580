.activities-section {
    max-width: 1200px;
    padding-top: 100px;

    h1 {
        font-size: 70px;
        font-weight: 500;
        color: white;

        @media screen and (max-width: 600px) {
            text-align: center;
            font-size: 50px;
        }
    }

    .activities {
        display: flex;
        justify-content: center;
        align-items: center;

        gap: 2rem;

        flex-wrap: wrap;
    }
}