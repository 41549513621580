.button {
    border: 1px solid rgba(255, 255, 255, 0.212);
    background-color: #177140;
    border-radius: 10px;
    padding: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: all 0.2s ease;
    color: white;

    font-weight: 400;
    font-size: 14px;

    text-align: center;

    &:hover {
        background-color: #10532e;
    }

    &:active {
        background-color: #0e1e15;
    }
}