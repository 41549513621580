@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0);
    }

    20% {
        transform: translate(-5px, 0) rotate(-10deg);
    }

    30% {
        transform: translate(5px, 0) rotate(10deg);
    }

    50% {
        transform: translate(-5px, 0) rotate(-5deg);
    }

    60% {
        transform: translate(5px, 0) rotate(5deg);
    }

    100% {
        transform: translate(0, 0) rotate(0);
    }
}

nav {
    position: fixed;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #0e1e1500;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    transition: 0.1s ease;

    .container {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 0;

        box-sizing: border-box;
    }

    h2,
    a {
        font-family: "Poppins", sans-serif;
        text-decoration: none;
    }

    .logo {

        img {
            width: 60px;
            height: 60px;
        }
    }

    .logo {
        font-size: 16px;
        font-weight: 500;
    }

    .links {
        display: flex;
        align-items: center;
        gap: 2rem;

        @media (max-width: 767.98px) {
            display: none;
            padding: 0;
            margin: 0;
        }

        a {
            font-size: 13px;
            font-weight: 400;

        }

        .link {
            position: relative;
            font-size: 13px;
            font-weight: 400;
            transition: 0.3s ease;
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 0);

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                width: 0%;
                border-bottom: 2px solid white;
                transition: 0.5s ease;
            }

            &:hover {
                &:before {
                    width: 100%;
                }
            }
        }
    }

    .dropdown-menu {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        @media (max-width: 767.98px) {
            display: flex;
        }

        .menu-container {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;

            background-color: #177140;
            padding: 5px;
            border: 1px solid rgba(255, 255, 255, 0.212);
            transition: 0.3s ease;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: #10532e;
            }

            &:active {
                background-color: #0e1e15;
            }

            .menu {
                position: absolute;
                width: 150px;
                height: 300px;
                background-color: rgba(238, 238, 238, 0.949);
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.385);
                border-radius: 10px;
                top: 60px;
                right: 25px;
                padding: 1rem;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                z-index: -1;
                opacity: 0;
                pointer-events: none;

                transition: 0.3s ease;

                .link {
                    padding: 1rem 10px;
                    border-radius: 10px;
                    transition: 0.3s ease;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &:hover {
                        background-color: rgb(255, 255, 255);
                    }
                }
            }

            .toggled {
                opacity: 100;
                z-index: 1;
                transform: translateY(20px);
                pointer-events: all;
            }
        }
    }
}

.notification {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;


    .bell {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        transition: 0.3s ease;

        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #274139;
        border: 1px solid rgba(255, 255, 255, 0.112);

        .animated {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            background-color: #33574d;
        }
    }

    .socialLinks {
        position: absolute;
        background-color: rgba(238, 238, 238, 0.949);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.385);
        border-radius: 10px;
        top: 35px;
        right: -10px;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        transition: 0.3s ease;

        h2 {
            font-weight: 500;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }

        .logos {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            gap: 1rem;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #177140;
                padding: 5px;
                border-radius: 8px;
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.195);
                border: 1px solid rgba(255, 255, 255, 0.101);

                transition: 0.3s ease;

                &:hover {
                    background-color: #0d3e23;
                    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.276);
                }
            }
        }
    }

    .toggled {
        opacity: 100;
        z-index: 1;
        transform: translateY(20px);
        pointer-events: all;
    }
}

.icon-shake {
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}

.dark {
    background-color: #0e1e15;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.338);
    border-bottom: 1px solid rgba(255, 255, 255, 0.076);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {

    .links {
        display: none;
    }

    .dropdown-menu {
        display: flex;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}