.scrollButton {
    position: fixed;
    bottom: 50px;
    right: 50px;

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 50px;
        height: 50px;
        border-radius: 50%;

        background-color: #177140;
        border: 2px solid rgba(255, 255, 255, 0.098);
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.315);

        transition: 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: #0e4829;
        }

        &:active {
            background-color: #0e1e15;
        }
    }
}