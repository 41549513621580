.mission {
    width: 100%;
    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    .title {

        h1 {
            color: #457466;
            font-size: 60px;
            font-weight: 500;
            line-height: 55px;
            margin-bottom: 1rem;

            text-align: right;

            @media screen and (max-width: 600px) {
                font-size: 50px;
            }
        }
    }

    .line {
        width: 100px;
        height: 2px;
        background-color: #457466;
    }

    p {
        color: rgb(182, 182, 182);
        font-weight: 300;
        line-height: 30px;
        font-size: 18px;
        margin-top: 3rem;
    }

    .mission-container {
        max-width: 1200px;
        border-radius: 10px;
        padding: 1rem 0;
        display: flex;
        justify-content: end;
        
        

        .container {
            width: 60%;
            display: flex;
            gap: 3rem;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }
            

            .text {
                display: flex;
                flex-direction: column;
                align-items: end;
            }
        }
    }
}