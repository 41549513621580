body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #162521;
    margin: 0;
    padding: 0;

    .content {
        margin: 0px 2rem;
    }
}