.comites {
    max-width: 1200px;

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        h1 {
            position: relative;
            font-size: 45px;
            font-weight: 500;
            color: #457466;
    
            @media screen and (max-width: 600px) {
                text-align: center;
                font-size: 45px;
            }
        }
    }
}